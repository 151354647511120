import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import 'index.css';
import * as Sentry from '@sentry/react';

const sentryEnabled = !process.env.REACT_APP_DISABLE_SENTRY;

Sentry.init({
  dsn: sentryEnabled
    ? 'https://285b105fb85f852b758282be7aa22444@o4508086168125440.ingest.de.sentry.io/4508399154495568'
    : undefined,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: sentryEnabled,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/dev.plateforme-ingenierie\.hellio\.dev/,
    /^https:\/\/staging.plateforme-ingenierie\.hellio\.dev/,
    /^https:\/\/plateforme-ingenierie\.hellio\.dev/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

root.render(
  <Router history={history}>
    <App />
  </Router>,
);
