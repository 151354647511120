let prefixer: string | undefined;

const hostname = window.location.hostname;
const port = window.location.port;

switch (hostname) {
  case process.env.REACT_APP_STAGING_HOST:
    prefixer = process.env.REACT_APP_STAGING_API_URL;
    break;
  case process.env.REACT_APP_DEV_HOST:
    prefixer = process.env.REACT_APP_DEV_API_URL;
    break;
  case process.env.REACT_APP_PROD_HOST:
    prefixer = process.env.REACT_APP_PROD_API_URL;
    break;
  default:
    if (port === '3000') prefixer = process.env.REACT_APP_LOCAL_API_URL;
    else prefixer = process.env.REACT_APP_DEV_API_URL;
}

export interface GlobalPaginate {
  page?: number;
  per_page?: number;
  keyword?: string;
}

export const AuthUrls = {
  PASSWORD_LOGIN: `${prefixer}/auth/login`,
  RESET_PASSWORD_INIT: `${prefixer}/auth/forgot-password`,
  RESET_PASSWORD: `${prefixer}/auth/reset-password`,
  CREATE_PASSWORD: `${prefixer}/auth/create-password`,
  REFRESH_TOKEN: `${prefixer}/auth/refresh-token`,
  IMPERSONATE: `${prefixer}/auth/impersonate`,
};

export const UserUrls = {
  GET_CONNECTED_INFO: `${prefixer}/user/me`,
  PASSWORD_CHANGE: `${prefixer}/user/update/password`,
  GET_ACCOUNT_INFO: `${prefixer}/user/infos`,
  CHANGE_AVATAR: `${prefixer}/user/update/avatar`,
  GET_ACCOUNT_SF_OWNER_INFO: `${prefixer}/user/me/sf-owner`,
};

export const TransactionUrls = {
  LIST: (page: number, per_page: number) =>
    `${prefixer}/transactions?page=${page}&per_page=${per_page}`,
  DETAILS: (id: string) => `${prefixer}/transactions/${id}`,
  MAR: (id: string) => `${prefixer}/transactions/${id}/mar`,
  UPLOAD: (id: string | number, category: string) =>
    `${prefixer}/transactions/${id}/${category}/upload`,
  LIST_DOCUMENTS: (id: number | string) =>
    `${prefixer}/transactions/${id}/documents`,
  DOWNLOAD_DOCUMENT: (id: string | number) =>
    `${prefixer}/transactions/documents/${id}/download`,
};
